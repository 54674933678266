<template>
  <!-- General Information -->
  <div
    class="tab-pane fade show active"
    id="general-info"
    role="tabpanel"
    aria-labelledby="general-info-tab"
  >
    <!-- Tab Content -->
    <div class="row align-items-stretch mb-3">
      <!-- MAIN CONTENT -->

      <div class="col-12 mb-3 col-xl mb-xl-0">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="custom-card-title">
              <i class="far fa-list-alt"></i>
              <span>GENEL BİLGİLER</span>
            </h5>
            <hr />
            <!-- Package Name -->
            <div class="form-group mb-3">
              <WeInput
                label="Paket Adı"
                name="package_name"
                placeholder="Paket Adı"
                v-bind:required="true"
                v-model="packageName"
                v-bind:error="$v.packageName.$error"
              />
            </div>
            <!-- ./Package Name -->

            <!-- Slug -->
            <WeSlugCheck
              v-bind:value="packageName"
              v-bind:id="$route.params.id"
              v-bind:slug="packages.info.slug"
              v-bind:search="true"
              v-on:slugify="changeSlug"
              url-fragment="urun"
              type="package"
            />
            <!-- ./Slug -->

            <!-- Stock Code -->
            <div class="form-group">
              <WeInput
                label="Stok Kodu"
                name="stock_code"
                placeholder="Stok Kodu"
                v-bind:required="true"
                v-model="skuNo"
                v-bind:error="$v.skuNo.$error"
              />
            </div>
            <!-- ./Stock Code -->

            <div class="form-row">
              <div class="col">
                <!-- Currency -->
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label
                        for="currency"
                        class="custom-label"
                        v-bind:class="{
                          'text-danger': $v.packageCurrencyCode.$error,
                        }"
                        >Para Birimi</label
                      >
                    </div>
                    <div class="col-auto" v-if="$v.packageCurrencyCode.$error">
                      <div
                        class="text-danger ml-auto"
                        v-if="!$v.packageCurrencyCode.required"
                      >
                        <small><i>Zorunlu Alan</i></small>
                      </div>
                    </div>
                  </div>
                  <select
                    name="currency"
                    id="currency"
                    class="custom-select"
                    :class="{
                      'border-danger': $v.packageCurrencyCode.$error,
                    }"
                    v-model="packageCurrencyCode"
                    v-on:change="changeCurrencySymbol"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="currency in packages.currencies"
                      v-bind:key="currency.id"
                      v-bind:value="currency.id"
                    >
                      {{ currency.name }}
                    </option>
                  </select>
                </div>
                <!-- ./Currency -->
              </div>
            </div>

            <div class="form-row">
              <div class="col-12 mb-3 col-md mb-md-0">
                <WePriceInput
                  label="Satış Fiyatı"
                  name="normal_price"
                  v-bind:required="true"
                  v-model="packagePrice"
                  v-bind:error="$v.packagePrice.$error"
                  v-bind:append="packages.currencySymbol"
                  ref="normalPrice"
                />
              </div>
              <div class="col-12 col-md">
                <WeInput
                  label="İskonto Oranı"
                  name="discountRate"
                  v-bind:prepend="'%'"
                  v-model="discountRate"
                  v-bind:filter-number="true"
                />
              </div>
              <div class="col-12 col-md">
                <WePriceInput
                  label="İndirimli Fiyat"
                  name="discounted_price"
                  v-bind:max-value="packagePrice.unmask"
                  v-bind:append="packages.currencySymbol"
                  v-model="discountedPrice"
                  v-on:blur="calculateDiscountRate"
                  ref="discountedPrice"
                />
              </div>
              <!-- Tax Amount -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <WeInput
                  label="KDV"
                  name="tax_amount"
                  v-bind:required="true"
                  v-bind:prepend="'%'"
                  v-model="taxAmount"
                  v-bind:error="$v.taxAmount.$error"
                />
              </div>
              <!-- ./Tax Amount -->
            </div>

            <!-- Stock Information -->
            <div class="form-row">
              <div class="col-12 mb-3 col-md mb-md-0">
                <WeInput
                  label="Stok"
                  name="stock"
                  v-bind:required="true"
                  v-model="packageQty"
                  v-bind:error="$v.packageQty.$error"
                />
              </div>
              <!-- Stock Type -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label
                        for="stock_type"
                        class="custom-label"
                        v-bind:class="{
                          'text-danger': $v.packageStockType.$error,
                        }"
                        >Stok Tipi</label
                      >
                    </div>
                    <div class="col-auto" v-if="$v.packageStockType.$error">
                      <div
                        class="text-danger ml-auto"
                        v-if="!$v.packageStockType.required"
                      >
                        <small><i>Zorunlu Alan</i></small>
                      </div>
                    </div>
                  </div>
                  <select
                    name="stock_type"
                    id="stock_type"
                    class="custom-select"
                    :class="{
                      'border-danger': $v.packageStockType.$error,
                    }"
                    v-model="packageStockType"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="option in packages.stockTypeOptions"
                      v-bind:key="option.id"
                      v-bind:value="option.id"
                    >
                      {{ option.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- ./Stock Type -->
            </div>
            <!-- ./Stock Information -->

            <!-- Product Setting -->
            <label class="custom-label mb-2">Durum</label>
            <div class="row">
              <div class="col-12 col-md">
                <WeSwitch
                  v-model="packages.info.is_active"
                  label="Aktif"
                  v-on:change="statusChange"
                />
              </div>
              <div class="col-12 col-md">
                <WeSwitch v-model="packages.info.is_new" label="Yeni" />
              </div>
              <div class="col-12 col-md">
                <WeSwitch
                  v-model="packages.info.is_featured"
                  label="Öne Çıkar"
                />
              </div>
            </div>
            <!-- Product Setting -->
          </div>
        </div>
      </div>

      <div class="col-12 col-xl">
        <!-- Category Section -->
        <WeCategory
          v-bind:items="packages.categoryList"
          v-model="packages.info.categories"
          v-bind:placeholder="$t('searchCategory')"
          v-bind:title="$filters.toUpperCase($t('categories'))"
        />
        <!-- ./Category Section -->
      </div>

      <!-- ./MAIN CONTENT -->
    </div>
    <!-- ./Tab Content -->

    <!-- Package Products Section -->
    <WeProduct
      title="Ürünler"
      name="product"
      icon="fas fa-cube"
      class="mb-3"
      v-bind:searchable="true"
      v-bind:quantity="true"
      searchText="Seçili Ürünlerde Ara"
      v-model="packageProducts"
      v-bind:show-total="true"
      v-bind:columns="columns"
      v-bind:collapsed="packageProducts.length == 0"
    />

    <!-- ./Package Products Section -->

    <!-- ***** Image Widget ***** -->
    <div class="mb-3">
      <WeImageUploader
        title="Görseller"
        multiple="true"
        v-bind:fixedMetaTitle="packageName"
        v-bind:fixedMetaAlt="packageName"
        v-model="packages.info.image"
      />
    </div>
    <!-- ***** Image Widget ***** -->
  </div>
  <!-- ./General Information -->
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { setValue, getValue } from "vue-currency-input";

export default {
  name: "Information",
  data() {
    return {
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image" },
        { name: "sku_no", th: "Stok Kodu", type: "string" },
        { name: "name", th: "Ürün Adı", type: "string" },
        { name: "quantity", th: "Miktar", type: "quantity", width: "20" },
        { name: "price", th: "Fiyatı", type: "currency" },
      ],
      currencySetting: {
        locale: localization.locale.code,
        currency: null,
        precision: 2,
        allowNegative: false,
        autoDecimalMode: false,
      },
    };
  },
  validations: {
    packageName: {
      required,
    },
    packagePrice: {
      unmask: {
        required,
      },
    },
    packageQty: {
      required,
    },
    taxAmount: {
      required,
    },
    skuNo: {
      required,
    },
    packageStockType: {
      required: function () {
        return this.packageStockType !== "-1" && this.packageStockType !== -1;
      },
    },
    packageCurrencyCode: {
      required: function () {
        return (
          this.packageCurrencyCode !== "-1" && this.packageCurrencyCode !== -1
        );
      },
    },
    packageProducts: {
      required: function () {
        return this.packages.info.products.length;
      },
    },
  },
  methods: {
    ...mapMutations("packages", ["appendProduct", "changeCurrencySymbol"]),
    updateCurrentPrice(data) {
      if (data.vatExcluded > 0) {
        this.packagePrice.unmask = data.vatExcluded;
        this.packages.currencySymbol = data.currency.symbol;
      } else {
        this.packagePrice.price = 0;
      }
    },
    calculateDiscountedPrice() {
      let normalPrice = this.packagePrice.unmask;
      if (this.discountRate && this.discountRate > 0) {
        if (normalPrice > 0) {
          this.discountedPrice.unmask =
            (normalPrice * (100 - this.discountRate)) / 100;
        }
      }
    },
    calculateDiscountRate() {
      let normalPrice = this.packagePrice.unmask;
      let discountedPrice = this.discountedPrice.unmask;
      if (discountedPrice && normalPrice) {
        let result = 100 - (discountedPrice * 100) / normalPrice;
        if (result >= 0) {
          // this.discountRate = result;
          this.discountRate = Math.round(result * 100) / 100;
        }
      }
    },

    changeSlug(data) {
      this.packages.info.slug = data;
    },
    checkActiveMarketplaces() {
      return this.packages.detail.marketplaces.filter(
        (mp) => mp.market.is_active && mp.active
      ).length;
    },

    statusChange(data) {
      if (!data && this.marketplaceConfig && this.checkActiveMarketplaces()) {
        this.$swal({
          title:
            "Uyarı: Paket bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
          icon: "warning",
          showCancelButton: false,
        })
      }
    },
  },
  computed: {
    ...mapState(["packages", "session"]),
    marketplaceConfig() {
      return (
        this.session.config["site.marketplace_integration"] == "1" ||
        this.session.config["site.marketplace_integration"] == 1
      );
    },
    packageName: {
      get() {
        return this.packages.info.name;
      },
      set(value) {
        this.packages.info.name = value;
      },
    },
    packagePrice() {
      return this.packages.info.normalPrice;
    },
    packageQty: {
      get() {
        return this.packages.info.stock;
      },
      set(value) {
        this.packages.info.stock = value;
        if (
          value <= 0 &&
          this.marketplaceConfig &&
          this.checkActiveMarketplaces()
        ) {
          this.$swal({
            title:
              "Uyarı: Paket bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
            icon: "warning",
            showCancelButton: false,
          })
        }
      },
    },
    packageStockType: {
      get() {
        let stockType = this.packages.info.selected.stockType;
        if (stockType == "-1" || (stockType == -1 && !this.$route.params.id)) {
          stockType = this.session.config["site.stock_type"];
        }

        return stockType;
      },
      set(value) {
        this.packages.info.selected.stockType = value;
      },
    },
    packageCurrencyCode: {
      get() {
        return this.packages.info.selected.currency;
      },
      set(value) {
        this.packages.info.selected.currency = value;
      },
    },
    packageProducts: {
      get() {
        return this.packages.info.products;
      },
      set(value) {
        this.packages.info.products = value;
      },
    },
    taxAmount: {
      get() {
        return this.packages.info.taxAmount;
      },
      set(value) {
        this.packages.info.taxAmount = value;
      },
    },
    discountedPrice() {
      return this.packages.info.discountedPrice;
    },
    discountRate: {
      get() {
        return this.packages.info.discountRate;
      },
      set(value) {
        this.packages.info.discountRate = value;
      },
    },
    skuNo: {
      get() {
        return this.packages.info.stockCode;
      },
      set(value) {
        this.packages.info.stockCode = value;
      },
    },
  },
  watch: {
    "packages.info.categories": function (newVal) {
      if (newVal && newVal.length) {
        const selectedCategory = newVal[newVal.length - 1];
        this.taxAmount = selectedCategory.tax || 0;
      }
    },
    discountRate: function () {
      this.calculateDiscountedPrice();
    },
    "packagePrice.unmask": function () {
      this.calculateDiscountedPrice();
    },
  },
};
</script>
