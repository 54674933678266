<template>
  <div>
    <TreeSelect
      v-bind:data="marketplace.categories"
      v-on:get-category="getCategory"
      v-on:selected-category="selectedCategory = $event"
      v-bind:disabled="disabled"
    />
    <WeSubmitButton
      v-if="checkAvailable"
      v-bind:disabled="disabled"
      v-on:submit="onSave"
      btn-text="Kategori Kaydet"
    />
  </div>
</template>
<script>
import TreeSelect from "./TreeSelect/Index";
import { mapActions } from "vuex";

export default {
  name: "CategoryTree",
  data() {
    return {
      ready: false,
      selectedCategory: null,
    };
  },
  components: {
    TreeSelect,
  },
  props: {
    category: {
      default: () => {},
    },
    marketplace: {
      default: null,
    },
    disabled: {
      default: false,
    },
  },
  model: {
    prop: "category",
  },
  methods: {
    ...mapActions("marketplace", ["getList", "getMarketplaceCategory"]),
    objToArray(items) {
      const resultData = [];
      if (items) {
        const dataObj = JSON.parse(items);
        Object.entries(dataObj).forEach(([key, item]) => {
          const data = {};
          data.id = key;
          data.name = item;
          data.children = [];
          data.parents = [];

          resultData.push(data);
        });
      }
      return resultData;
    },
    getCategory(category) {
      if (category) {
        this.marketplace.market.code = category.id;
        this.getMarketplaceCategory({
          marketplace: this.marketplace.market,
          onSuccess: (result) => {
            const resultData = this.objToArray(
              result.data && result.data.data ? result.data.data : null
            );
            if (category) {
              category.children = resultData;
            } else {
              this.marketplace.categories = resultData;
            }
          },
        });
      }
    },
    getCategoryBreadcrumb(category) {
      let result = "";
      let parents = [];

      function getCategoryParents(category) {
        if (category.parents && category.parents.length) {
          parents.push(category.parents[0].name);
          getCategoryParents(category.parents[0]);
        }
      }

      getCategoryParents(category);

      if (parents && parents.length) {
        parents = parents.reverse();
        parents.push(category.name);
        result = parents.join("/");
      } else {
        result = category.name;
      }

      return result;
    },
    onSave() {
      if (this.checkAvailable) {
        this.$emit("on-selected", this.selectedCategory);
      }
    },
  },
  computed: {
    checkAvailable() {
      return this.selectedCategory && !this.selectedCategory.children.length;
    },
  },
  mounted() {
    delete this.marketplace.market.code;
    if (this.marketplace.market.is_active) {
      this.getMarketplaceCategory({
        marketplace: this.marketplace.market,
        onSuccess: (result) => {
          const resultData = this.objToArray(
            result.data && result.data.data ? result.data.data : null
          );

          this.marketplace.categories = resultData;
        },
      });
    }
  },
};
</script>