<template>
  <!-- Product Detail -->
  <div
    class="tab-pane fade"
    id="packet-detail"
    role="tabpanel"
    aria-labelledby="packet-detail-tab"
  >
    <!-- Tab Content -->
    <div class="row">
      <!-- MAIN CONTENT -->
      <div class="col-12 mb-3 col-xl mb-xl-0">
        <div class="card mb-3">
          <div class="card-body">
            <h5 class="custom-card-title">
              <i class="fas fa-bars"></i>
              <span>DETAYLAR</span>
            </h5>
            <hr />

            <!-- Brand & Model -->
            <div class="form-row">
              <!-- Brand -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <label for="brand" class="custom-label">Marka</label>
                <select
                  name="brand"
                  id="brand"
                  class="custom-select"
                  v-model="packages.detail.selected.brand"
                  v-on:change="getModelList"
                >
                  <option value="-1">Seçiniz</option>
                  <option
                    v-for="brand in packages.brands"
                    v-bind:key="brand.id"
                    v-bind:value="brand.id"
                  >
                    {{ brand.name }}
                  </option>
                </select>
              </div>
              <!-- ./Brand -->

              <!-- Model -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <label for="model" class="custom-label">Model</label>
                  <select
                    name="model"
                    id="model"
                    class="custom-select"
                    v-bind:disabled="packages.models && packages.models.length"
                    v-model="packages.detail.selected.model"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="model in packages.models"
                      v-bind:key="model.id"
                      v-bind:value="model.id"
                    >
                      {{ model.name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- ./Model -->
            </div>
            <!-- ./Brand & Model -->

            <!-- Packet & Parcel -->
            <div class="form-row">
              <!-- Packet Barcode -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <div class="form-group">
                  <WeInput
                    type="text"
                    id="packet_barcode"
                    name="packet_barcode"
                    placeholder="Paket Kodu"
                    v-model="packages.detail.packageBarcode"
                    label="Paket Barkodu"
                  />
                </div>
              </div>
              <!-- ./Packet Barcode -->

              <!-- Parcel Barcode -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <WeInput
                    type="text"
                    id="parcel_code"
                    name="parcel_code"
                    placeholder="Koli Kodu"
                    v-model="packages.detail.parcelCode"
                    label="Koli Kodu"
                  />
                </div>
              </div>
              <!-- ./Parcel Barcode -->
            </div>
            <!-- ./Packet & Parcel -->

            <!-- TAX & Buying Price & Warranty -->
            <div class="form-row">
              <!-- Buying Price -->
              <div class="col-12 mb-3 col-md mb-md-0">
                <WePriceInput
                  label="Alış Fiyatı"
                  name="buying_price"
                  v-model="packages.detail.purchasePrice"
                  v-bind:append="packages.currencySymbol"
                />
              </div>
              <!-- ./Buying Price -->

              <!-- Warranty -->
              <div class="col-12 col-md">
                <div class="form-group">
                  <label for="warranty" class="custom-label"
                    >Garanti Süresi</label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id="warranty"
                      name="warranty"
                      placeholder="Garanti Süresi"
                      v-model="packages.detail.warranty"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">Yıl</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ./Warranty -->
            </div>
            <!-- ./TAX & Buying Price & Warranty -->
          </div>
        </div>

        <!-- Cargo Information -->
        <WeCargoForm class="mb-3" v-model="packages.detail.cargoPackage" />
        <!-- ./Cargo Information -->

        <div class="card mb-3">
          <div class="card-body">
            <h5 class="custom-card-title">
              <i class="fas fa-box"></i>
              <span>AÇIKLAMA</span>
            </h5>
            <hr />

            <WeMultipleDescription
              v-model="packages.detail.descriptions"
              v-bind:access-token="session.accessToken"
              v-bind:name="'package'"
              compact="true"
            />

            <WeMetaData v-model="packages.detail.meta" />
          </div>
        </div>
      </div>
      <!-- ./MAIN CONTENT -->

      <!-- ASIDE -->
      <div class="col-12 col-xl-4">
        <!-- Feature Section -->
        <WeFeature
          v-bind:feature-value="true"
          v-model="packages.detail.features"
        />
        <!-- ./Feature Section -->

        <!-- Cargo Section -->
        <div class="mb-3" id="cargo-accordion">
          <div class="card">
            <!-- Accordion Header -->
            <div class="card-header p-0" id="cargo-head">
              <h2 class="mb-0">
                <button
                  class="btn btn-white btn-block text-left p-3 collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#cargo-collapse"
                  aria-expanded="true"
                  aria-controls="cargo-collapse"
                >
                  <h5 class="custom-card-title">
                    <i class="fas fa-truck"></i>
                    <span>KARGO FİRMALARI</span>
                    <i
                      class="
                        fas
                        fa-chevron-down
                        float-right
                        font-14
                        mt-1
                        text-muted
                      "
                    ></i>
                  </h5>
                </button>
              </h2>
            </div>
            <!-- Accordion Header -->

            <!-- Cargo Accordion Body -->
            <div
              id="cargo-collapse"
              class="collapse"
              aria-labelledby="cargo-head"
              data-parent="#cargo-search"
            >
              <div class="card-body">
                <!-- Search Cargo -->
                <WeInput
                  type="text"
                  name="cargo_search"
                  id="cargo-search"
                  placeholder="Kargo Ara"
                />
                <!-- ./Search Cargo -->
              </div>
            </div>

            <!-- ./Cargo Accordion Body -->
          </div>
        </div>
        <!-- ./Cargo Section -->

        <!-- Recommended Products Section -->
        <WeProduct
          class="mb-3"
          title="Önerilen Ürünler"
          name="recommended"
          icon="fas fa-thumbs-up"
          v-model="packages.detail.recommendedProducts"
        />
        <!-- ./Recommended Products Section -->

        <!-- Matched Products Section -->
        <WeProduct
          class="mb-3"
          title="Uyumlu Ürünler"
          name="compatible"
          icon="fas fa-sync"
          v-model="packages.detail.compatibleProducts"
        />
        <!-- ./Matched Products Section -->
      </div>
      <!-- ./ASIDE -->
    </div>
    <!-- ./Tab Content -->
  </div>
  <!-- ./Product Detail -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Detail",
  methods: {
    ...mapActions("packages", ["getModelList"]),
  },
  computed: {
    ...mapState(["packages", "session"]),
  },
};
</script>
