<template>
  <WeCard>
    <h5 class="custom-card-title">
      <i class="fas fa-cart-plus"></i>
      <span>PAZARYERİ</span>
    </h5>
    <hr />
    <div class="row align-items-start">
      <!-- Marketplace Tab Header -->
      <div class="col-12 col-xl-auto">
        <ul
          class="nav nav-pills p-0 custom-scrollbar"
          id="pills-tab"
          role="tablist"
          style="
            white-space: nowrap;
            max-width: 100%;
            overflow: auto;
            display: block;
          "
        >
          <li
            class="nav-item d-inline-block mr-2 d-xl-block"
            role="presentation"
            v-for="(mp, index) in getMarketplaces"
            v-bind:key="mp.market.name"
          >
            <a
              class="
                d-flex
                align-items-center
                btn btn-light
                px-5
                justify-content-center
                w-100
              "
              style="min-height: 80px"
              data-toggle="pill"
              role="tab"
              aria-selected="true"
              v-bind:id="mp.market.name + '-tab'"
              v-bind:class="{
                'mb-xl-2': index !== getMarketplaces.length - 1,
                'ml-2 ml-xl-0': index !== 0,
                'glow-it--danger': $v.getMarketplaces.$each[index].$error,
              }"
              v-bind:href="'#' + mp.market.name + '-link'"
              v-bind:aria-controls="mp.market.name + '-link'"
            >
              <div class="p-3">
                <img
                  v-if="mp.market.logo"
                  v-bind:src="mp.market.logo"
                  width="80"
                />
                <span v-else class="font-weight-bold">
                  {{ mp.market.title }}
                </span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <!-- ./Marketplace Tab Header -->
      <div class="col-12 col-xl">
        <!-- Marketplace Tab Content -->
        <div class="tab-content" id="pills-tabContent">
          <div
            v-for="(item, index) in getMarketplaces"
            v-bind:key="item.market.name"
            v-bind:class="{ 'show active': index === 0 }"
            class="tab-pane fade"
            v-bind:id="item.market.name + '-link'"
            role="tabpanel"
            v-bind:aria-labelledby="item.market.name + '-tab'"
          >
            <WeCard
              v-if="
                item.market.name == 'n11' &&
                $route.params.id &&
                item.market.is_active
              "
              class="alert-info mb-4 col-12 col-xl-6"
            >
              <i class="fas fa-info-circle mr-2"></i> Bu ürünün güncel durumu,
              N11 Pazaryerine <b>1 Dakika</b> sonra yansıyacaktır.
            </WeCard>
            <Item
              v-bind:marketplaces="getMarketplaces"
              v-bind:index="index"
              v-bind:categories="categories"
              v-on:get-features="getFeatures"
              v-on:search-brand="getBrand"
              v-on:get-shipment-templates="getShipmentTemplateData"
              v-bind:v="$v.getMarketplaces.$each[index]"
              v-bind:submit-status="featureSubmit"
            />
          </div>
        </div>
        <!-- ./Marketplace Tab Content -->
      </div>
    </div>
  </WeCard>
  <!-- ./Marketplace Accordion Body -->
</template>
<script>
import Item from "./views/Item/Index";
import { mapActions } from "vuex";
import { requiredIf } from "vuelidate/lib/validators";
export default {
  name: "Content",
  data() {
    return {
      localData: this.data,
      featureSubmit: false,
    };
  },
  validations: {
    getMarketplaces: {
      $each: {
        selectedCategory: {
          required: requiredIf(function (parent) {
            return (
              parent.active &&
              parent.market.is_active &&
              (!parent.category || parent.category.id == null)
            );
          }),
        },
        brand: {
          required: requiredIf(function (parent) {
            return (
              parent.active &&
              parent.market.is_active &&
              parent.market.name === "trendyol" &&
              !parent.brand
            );
          }),
        },
        shipment_template: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              parent.market.name === "n11" &&
              !parent.shipment_template
            );
          }),
        },
        // catalog_id: {
        //   required: requiredIf(function (parent) {
        //     return (
        //       parent.market.is_active &&
        //       parent.active &&
        //       parent.market.name === "n11" &&
        //       !parent.catalog_id
        //     );
        //   }),
        // },
        category_attributes: {
          $each: {
            value: {
              required: requiredIf(function (parent) {
                // Parent array
                return parent.market_active && parent.is_require;
              }),
            },
          },
        },
        percent: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              parent.price_type !== "-1" &&
              parent.percent_type !== "-1" &&
              parent.percent_type !== "equal"
            );
          }),
        },
        sku_no: {
          required: requiredIf(function (parent) {
            return (
              parent.market.is_active &&
              parent.active &&
              parent.market.name === "hepsiburada" &&
              !parent.sku_no
            );
          }),
        },
      },
    },
  },
  props: {
    marketplaces: {
      default: () => [],
    },
    data: {
      default: null,
    },
    categories: {
      default: () => [],
    },
  },
  model: {
    prop: "data",
  },
  components: {
    Item,
  },
  methods: {
    ...mapActions("marketplace", [
      "getMarketplaceCategoryFeatures",
      "searchBrand",
      "getShipmentTemplates",
    ]),

    getFeatures(item) {
      this.featureSubmit = true;
      if (item.selectedCategory || item.category) {
        let code;

        if (item.selectedCategory) {
          code = item.selectedCategory.id;
        } else if (item.category) {
          code = item.category.category_code;
        }

        this.getMarketplaceCategoryFeatures({
          marketplace: {
            ...item.market,
            category_code: code,
          },
          onSuccess: (result) => {
            if (result.data && result.data.items) {
              let resultData = result.data.items;
              let attributes = [];

              if (resultData && resultData.length) {
                attributes = resultData
                  .map((item) => {
                    if (item.values && typeof item.values == "object") {
                      item.values = Object.keys(item.values).map((key) => {
                        return item.values[key];
                      }, []);
                    }
                    return item;
                  })
                  .sort((a, b) => b.is_require - a.is_require);
              }

              item.category_attributes = attributes;
            }
          },
          onFinish: () => {
            this.featureSubmit = false;
          },
        });
      } else {
        this.featureSubmit = false;
      }
    },
    getBrand(data) {
      this.searchBrand({
        form: data,
        onSuccess: (result) => {
          const resultData = result.data ? result.data.data : null;
          if (resultData) {
            data.marketplace.brand_options = JSON.parse(resultData);
          }
          data.loading(false);
        },
      });
    },
    getShipmentTemplateData(data) {
      this.getShipmentTemplates({
        onSuccess: (response) => {
          if (
            response.data &&
            response.data.status &&
            response.data.status !== "failure"
          ) {
            data.shipment_templates = JSON.parse(response.data.data);
          } else {
            console.warn(`N11 Hatası: (${response.data.error["n11.0"]})`);
          }
        },
      });
    },
  },
  computed: {
    getMarketplaces: {
      get() {
        return this.marketplaces && this.marketplaces.length
          ? this.marketplaces
              .filter((item) => {
                if (item.hasOwnProperty("category_attributes")) {
                  item.category_attributes.map((ca) => {
                    ca.market_active = item.active;

                    return ca;
                  });
                }
                return item;
              })
              .sort((a, b) => b.market.is_active - a.market.is_active)
          : [];
      },
      set(value) {
        this.computedData = value;
      },
    },
    computedData: {
      get() {
        return this.data;
      },
      set(value) {
        this.localData = value;
      },
    },
  },
  watch: {
    marketplaces: function (newVal) {
      this.computedData = newVal;
    },
  },
};
</script>
