<template>
  <div
    class="tab-pane fade"
    id="marketplace-detail"
    role="tabpanel"
    aria-labelledby="marketplace-detail-tab"
  >
    <!-- Marketplace -->
    <Content
      v-bind:marketplaces="marketplaceData"
      v-bind:categories="packages.info.categories"
      v-model="packages.detail.marketplaces"
      ref="content"
    />
    <!-- ./Marketplace -->
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Content from "./views/Content/Index";
export default {
  name: "Marketplace",
  data() {
    return {
      marketplaceData: [],
    };
  },
  components: {
    Content,
  },
  methods: {
    ...mapActions("marketplace", ["getList"]),
    setMarketplaceList() {
      const marketList = this.marketplace.list;
      const packageMarketplace = this.packages.detail.marketplaces; // Data From Store -> Api
      if (marketList && marketList.length) {
        marketList.forEach((marketplace) => {
          let localMarket = this.marketplaceData.find(
            (local) => local.market.id == marketplace.id
          );

          const data = {};
          data.market = helper.clone(marketplace);
          data.categories = localMarket ? localMarket.categories : [];

          if (!localMarket) {
            data.selectedCategory = null;
            data.category = {
              id: null,
              category_name: "Yeni Kategori Eşleştir",
            };

            data.percent_type = "equal";
            data.price_type = "price";
            data.category_attributes = [];
          }

          switch (marketplace.name) {
            case "trendyol":
            case "pazarama":
              data.brand_options = localMarket ? localMarket.brand_options : [];
              break;

            case "n11":
              data.shipment_templates = localMarket
                ? localMarket.shipment_templates
                : [];
            case "hepsiburada":
              data.sku_no = localMarket ? localMarket.sku_no : null;
              break;
          }

          const categoryMarketplace = this.categoryWithMarketplaces.find(
            (category) => {
              return category.marketplaces.filter(
                (cm) => cm.marketplace_id === marketplace.id
              ).length;
            }
          );

          if (categoryMarketplace) {
            const matchedCategory = categoryMarketplace.marketplaces.find(
              (cm) => cm.marketplace_id === marketplace.id
            );
            if (matchedCategory) {
              // data.category_code = matchedCategory.category_code; // Filtered data [0] -> max
              data.category_id = categoryMarketplace.id;
            }
          }

          // Data from API -> package Detail
          const index = packageMarketplace.findIndex(
            (m) => m.id === marketplace.id
          );
          if (index >= 0) {
            const pmData = packageMarketplace[index];
            this.updateMarketData(pmData, localMarket || data);
          }

          if (localMarket) {
            helper.copy(data, localMarket);
          } else {
            this.marketplaceData.push(data);
          }
        });
        this.packages.detail.marketplaces = this.marketplaceData;
      }
    },

    /**
     * Data from API response @param marketplace
     * to update local & store (itself) data
     */
    updateMarketData(marketplace, target) {
      // Set Active
      target.active = marketplace.is_active || 0;

      // Set Additional Data
      if (marketplace.additional) {
        // Set Category Code
        target.category_code = marketplace.additional.category;

        // Trendyol & Pazarama brand
        if (
          marketplace.additional.brand_id &&
          marketplace.additional.brand_name
        ) {
          const brandData = {
            id: marketplace.additional.brand_id,
            name: marketplace.additional.brand_name,
          };

          // Set Brand
          target.brand = brandData;
        }

        // N11 Custom Data
        if (target.market.name == "n11") {
          if (marketplace.additional.shipmentTemplate) {
            target.shipment_template = marketplace.additional.shipmentTemplate;
          }
          if (marketplace.additional.n11CatalogId) {
            target.catalog_id = marketplace.additional.n11CatalogId;
          }
          if (marketplace.additional.n11ProductId) {
            target.n11product_id = marketplace.additional.n11ProductId;
          }
          if (marketplace.additional.deliveryDay) {
            target.n11_deliveryday = marketplace.additional.deliveryDay;
          }
        }

        // HB Custom Data
        if (target.market.name == "hepsiburada") {
          target.sku_no = marketplace.additional.hepsiburadaSku;
          if (marketplace.additional.deliveryDay) {
            target.hepsiburada_deliveryday = marketplace.additional.deliveryDay;
          }
        }

        if (target.market.name == "vodafone") {
          if (marketplace.additional.deliveryDay) {
            target.vodafone_deliveryday = marketplace.additional.deliveryDay;
          }
        }
      }

      // Change Price Keys
      target.price =
        marketplace.percent_type !== "equal" ? marketplace.sale_price : null;
      target.listing_price =
        marketplace.percent_type !== "equal" ? marketplace.list_price : null;

      // Set Quantity
      target.quantity = marketplace.quantity;

      // Set Category Attributes
      if (
        marketplace.category_attributes &&
        marketplace.category_attributes.length
      ) {
        target.category_attributes = marketplace.category_attributes.sort(
          (a, b) => b.is_require - a.is_require
        );
      }

      // Set Percent Type
      if (marketplace.percent) {
        target.percent =
          marketplace.percent_type !== "equal" ? marketplace.percent : null;
      }

      // Set Percent Type
      if (marketplace.percent_type) {
        target.percent_type = marketplace.percent_type;
      }

      // Set Price Type
      if (marketplace.price_type) {
        target.price_type = marketplace.price_type;
      }

      // New Match From Marketplace
      if (marketplace.marketplace_category) {
        target.selectedCategory = marketplace.marketplace_category;
      } else if (marketplace.ecommerce_category) {
        target.category = marketplace.ecommerce_category;
      }
    },
  },
  computed: {
    ...mapState(["packages", "marketplace"]),
    categoryWithMarketplaces() {
      return this.packages.info.categories.filter(
        (item) => item.marketplaces && item.marketplaces.length
      );
    },
  },
  watch: {
    "packages.info.categories": function (newVal) {
      if (newVal && newVal.length) {
        this.setMarketplaceList();
      }
    },
  },
  mounted() {
    this.getList({
      onSuccess: () => {
        this.setMarketplaceList();
      },
    });
  },
};
</script>
