<template>
  <div>
    <div class="w-100 border-bottom-2 my-3" v-bind:class="getBorderClass">
      <div class="row">
        <div class="col">
          <h5>
            <span v-bind:class="getTextClass">
              {{ marketplace.market.title }}
            </span>
          </h5>
        </div>
        <div class="col-auto" v-if="!isMarketActive">
          <span class="text-muted font-14 alert-dark rounded px-2 py-1"
            >Pazaryeri Pasif</span
          >
          <a
            v-bind:href="editRoute"
            target="_blank"
            class="px-2 py-1 rounded btn-outline-primary cursor-pointer ml-2"
            ><i class="fas fa-edit"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-5 p-0 mb-3 mb-lg-0">
        <div class="px-3">
          <div class="mb-3">
            <label class="custom-label">Pazaryerinde Yayınla</label>
            <WeSwitch
              v-model="marketplace.active"
              v-bind:show-status="true"
              v-bind:disabled="!isMarketActive"
              v-on:change="changeMarketplaceActive"
            />
          </div>

          <!-- Increase/Decrease Percent -->
          <label
            class="custom-label"
            v-bind:class="{ 'text-muted': !isMarketActive }"
            >Fiyat Oluştur</label
          >
          <div class="row align-items-center">
            <!-- Base Price -->
            <div class="col">
              <select
                class="custom-select custom-select-sm"
                v-bind:disabled="!isMarketActive"
                v-model="marketplace.price_type"
              >
                <option value="-1">Seçiniz</option>
                <option value="price">Satış Fiyatını</option>
                <option value="discounted_price">İndirimli Fiyatı</option>
              </select>
            </div>
            <!-- ./Base Price -->

            <!-- Percent Type -->
            <div class="col pl-0">
              <WeInput
                class="mb-0"
                v-bind:filter-number="true"
                max-value="100"
                v-bind:prepend="
                  marketplace.percent_type !== '-1' &&
                  marketplace.percent_type !== 'equal'
                    ? '%'
                    : ''
                "
                small-size="1"
                autocomplete="off"
                v-bind:error="v ? v.percent.$error : false"
                v-bind:disabled="
                  !isMarketActive ||
                  marketplace.percent_type == 'equal' ||
                  marketplace.percent_type == '-1'
                "
                v-model="marketplace.percent"
              >
                <template v-slot:append>
                  <select
                    class="custom-select custom-select-sm border-left-radius-0"
                    v-bind:disabled="!isMarketActive"
                    v-model="marketplace.percent_type"
                  >
                    <option value="-1">Seçiniz</option>
                    <option
                      v-for="percentOption in percentOptions"
                      v-bind:key="percentOption.id"
                      v-bind:value="percentOption.id"
                    >
                      {{ percentOption.name }}
                    </option>
                  </select>
                </template>
              </WeInput>
              <!-- ./Percent Type -->
            </div>
          </div>

          <!-- Marketplace Sell Price -->
          <small
            v-if="checkMarketplaceSellPrice && marketplace.active"
            class="text-danger"
            >{{ marketplace.market.title }} Satış Fiyatı:
            <span>{{ marketplaceSellPrice | moneyFormat }} </span></small
          >
          <!-- ./Marketplace Sell Price -->

          <!-- ./Increase/Decrease Percent -->
          <div class="my-4">
            <WeDivSeperator v-bind:text="$filters.toLowerCase($t('or'))" />
          </div>

          <!-- Listing Price -->
          <WeInput
            label="Liste Fiyatı"
            v-bind:currency="true"
            prepend="₺"
            small-size="1"
            v-bind:label-disabled="priceDisabled"
            v-bind:disabled="priceDisabled"
            v-model="marketplace.listing_price"
            v-on:unmask="marketplace.listing_price_unmask = $event"
          />
          <!-- ./Listing Price -->

          <!-- Price -->
          <WeInput
            label="Satış Fiyatı"
            v-bind:currency="true"
            prepend="₺"
            small-size="1"
            v-bind:label-disabled="priceDisabled"
            v-bind:disabled="priceDisabled"
            v-model="marketplace.price"
            v-on:unmask="marketplace.price_unmask = $event"
          />
          <!-- ./Price -->

          <hr />

          <!-- Quantity -->
          <WeInput
            class="mb-0"
            label="Stok Miktarı"
            v-bind:filter-number="true"
            v-bind:label-disabled="!isMarketActive"
            v-bind:disabled="!isMarketActive"
            small-size="1"
            v-model="marketplace.quantity"
          />
          <!-- ./Quantity -->
        </div>
      </div>
      <div class="col">
        <!-- Trendyol && Pazarama -->
        <template
          v-if="
            marketplace.market.name == 'trendyol' ||
            marketplace.market.name == 'pazarama'
          "
        >
          <div class="mb-3">
            <h6>
              <span v-bind:class="getTextClass"> Marka </span>
            </h6>
            <div class="col-12 col-lg-6 pl-0">
              <v-select
                class="custom-v-select-sm"
                v-bind:class="{
                  'v-select--error': v ? v.brand.$error : false,
                }"
                label="name"
                v-bind:filterable="false"
                v-bind:options="marketplace.brand_options"
                v-bind:disabled="!isMarketActive"
                v-model="marketplace.brand"
                @search="onSearch"
              >
                <template slot="no-options"> Arama yapın </template>
              </v-select>
            </div>
          </div>
        </template>
        <!-- ./Trendyol && Pazarama -->

        <!-- N11 -->
        <template v-if="marketplace.market.name == 'n11'">
          <div class="mb-3">
            <h6>
              <span v-bind:class="getTextClass"> Teslimat Şablonu </span>
            </h6>
            <div class="col-12 col-lg-5 pl-0">
              <v-select
                class="custom-v-select-sm"
                v-bind:class="{
                  'v-select--error': v ? v.shipment_template.$error : false,
                }"
                label="name"
                v-bind:filterable="false"
                v-bind:options="marketplace.shipment_templates"
                v-bind:disabled="!isMarketActive"
                v-bind:clearable="false"
                v-model="marketplace.shipment_template"
              >
                <template slot="no-options"> Gösterilecek Sonuç Yok </template>
              </v-select>
            </div>
          </div>
          <div class="mb-3">
            <h6>
              <span v-bind:class="getTextClass"> Katalog ID </span>
            </h6>
            <div class="col-12 col-lg-5 pl-0">
              <!-- <WeInput
              class="mb-0"
              v-model="marketplace.catalog_id"
              v-bind:small-size="true"
              v-bind:disabled="!isMarketActive"
              v-bind:error="v ? v.catalog_id.$error : false"
            /> -->
              <WeInput
                class="mb-0"
                v-model="marketplace.catalog_id"
                v-bind:small-size="true"
                v-bind:disabled="!isMarketActive"
              />
            </div>
          </div>
        </template>
        <!-- ./N11 -->

        <!-- Hepsiburada -->
        <template v-if="marketplace.market.name == 'hepsiburada'">
          <div class="mb-3">
            <h6>
              <span v-bind:class="getTextClass"> Hepsiburada Stok Kodu</span>
            </h6>
            <div class="col-12 col-lg-5 pl-0">
              <WeInput
                v-model="marketplace.sku_no"
                v-bind:small-size="true"
                v-bind:disabled="!isMarketActive"
                v-bind:error="v ? v.sku_no.$error : false"
              />
            </div>
          </div>
        </template>
        <!-- ./Hepsiburada -->

        <h6>
          <span v-bind:class="getTextClass">
            {{ marketplace.market.title }} Kategori</span
          >
        </h6>

        <div class="row align-items-center mb-3" v-if="matchedCategory">
          <div class="col-12 col-lg-6">
            <v-select
              v-bind:class="['custom-v-select-sm']"
              style="min-width: 192px"
              placeholder="Eşleşecek Kategori"
              v-bind:options="matchedCategories"
              label="category_name"
              v-bind:disabled="!isMarketActive"
              v-model="marketplace.category"
            ></v-select>
          </div>
          <template
            v-if="
              !showNewMatch && matchedCategory && !marketplace.selectedCategory
            "
          >
            <div class="col-auto p-0">
              <i class="fas fa-equals fa-sm"></i>
            </div>
            <div class="col-auto">
              <small class="text-muted"
                >{{ matchedCategory.marketplace_category }} ({{
                  marketplace.market.title
                }})</small
              >
            </div>
          </template>
        </div>

        <!-- Category Tree -->
        <template v-if="showNewMatch || !matchedCategory">
          <CategoryTree
            v-if="!marketplace.selectedCategory"
            v-bind:marketplace="marketplace"
            v-on:on-selected="marketplace.selectedCategory = $event"
            v-model="marketplace.selectedCategory"
            v-bind:disabled="!isMarketActive"
          />
        </template>
        <div
          v-if="marketplace.selectedCategory"
          v-on:click="onRemoveSelected"
          class="btn btn-light mb-3"
        >
          <i class="fas fa-times mr-2"></i>
          <span>{{ marketplace.selectedCategory.name }}</span>
        </div>
        <div v-if="v.selectedCategory.$error" class="text-danger mb-3">
          <i class="fas fa-exclamation-triangle mr-2"></i>
          <span>Lütfen Kategori eşleştirmesi yapın.</span>
        </div>
        <!-- ./Category Tree -->

        <div
          v-if="
            (!showNewMatch && matchedCategory) || marketplace.selectedCategory
          "
        >
          <h6>
            <span v-bind:class="getTextClass"> Kategori Özellikleri</span>
          </h6>

          <!-- Get Features -->
          <span
            class="btn btn-primary"
            v-bind:class="{ disabled: !marketplace.market.is_active }"
            v-if="!submitStatus"
            v-on:click="getFeatures"
          >
            <i class="fas fa-sync mr-2"></i> {{ submitCategoryText }}
          </span>
          <!-- ./Get Features -->

          <!-- Get Features Loader -->
          <span v-else-if="!checkCategoryAttributes" class="btn btn-primary">
            <i class="fas fa-spinner fa-spin"></i>
          </span>
          <!-- ./Get Features Loader -->

          <!-- Category Attributes -->
          <div class="row mt-3" v-if="checkCategoryAttributes">
            <div
              class="col-12 col-lg-6"
              v-for="(attr, index) in marketplace.category_attributes"
              v-bind:key="attr.code + '-' + index"
            >
              <div class="form-group">
                <WeRowInput
                  v-if="marketplace.market.name == 'n11'"
                  v-bind:form="
                    !attr.is_custom ? 'unreducedSearchSelect' : 'input'
                  "
                  size="sm"
                  select-prop="code"
                  input-class="col-12 col-lg-9"
                  search-key="query"
                  search-route="search/marketplace-category-attribute-values"
                  v-bind:show-asterix="attr.is_require"
                  v-bind:option-prop="attr.values"
                  v-bind:disabled="!isMarketActive"
                  v-bind:label="attr.name"
                  v-bind:clearable="true"
                  v-bind:search-form="getSearchForm(attr)"
                  v-bind:error="
                    v ? v.category_attributes.$each[index].$error : false
                  "
                  v-model="attr.value"
                />
                <WeRowInput
                  v-else
                  v-bind:form="!attr.is_custom ? 'unreducedSelect' : 'input'"
                  size="sm"
                  select-prop="code"
                  input-class="col-12 col-lg-9"
                  v-bind:show-asterix="attr.is_require"
                  v-bind:option-prop="attr.values"
                  v-bind:disabled="!isMarketActive"
                  v-bind:label="attr.name"
                  v-bind:clearable="true"
                  v-bind:error="
                    v ? v.category_attributes.$each[index].$error : false
                  "
                  v-model="attr.value"
                />
              </div>
            </div>
          </div>
          <!-- ./Category Attributes -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CategoryTree from "./components/CategoryTree/Index.vue";
import { mapState } from "vuex";
export default {
  name: "Item",
  components: {
    CategoryTree,
  },
  data() {
    return {
      showNewMatch: false,
      percentOptions: [
        {
          id: "equal",
          name: "Eşitle",
        },
        {
          id: "increase",
          name: "Artır",
        },
        {
          id: "decrease",
          name: "Azalt",
        },
      ],
      marketplaceConfig: {
        n11: {
          borderClass: "border-danger",
          textClass: "text-danger",
        },
        trendyol: {
          borderClass: "border-orange",
          textClass: "text-orange",
        },
        gittigidiyor: {
          borderClass: "border-primary",
          textClass: "text-primary",
        },
        hepsiburada: {
          borderClass: "border-orange",
          textClass: "text-orange",
        },
        pazarama: {
          borderClass: "border-pink",
          textClass: "text-pink",
        },
      },
    };
  },
  props: {
    marketplaces: {
      default: () => [],
    },
    index: {
      default: 0,
    },
    categories: {
      default: () => [],
    },
    submitStatus: {
      default: false,
    },
    v: null,
  },
  methods: {
    getConfig() {
      return this.marketplaceConfig[this.marketplace.market.name] || null;
    },
    getFeatures() {
      if (this.marketplace.market.is_active) {
        this.marketplace.category_attributes = [];
        this.$emit("get-features", this.marketplace);
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this.marketplace, this);
      }
    },
    search: _.debounce((loading, search, marketplace, vm) => {
      const data = {};
      data.marketplace = marketplace;
      data.loading = loading;
      data.query = search;

      vm.$emit("search-brand", data);
    }, 350),
    getAttrValues(attr) {
      if (!attr.values) return [];
      const valuesWithOutParent = attr.values.filter((item) => !item.parent_id);

      const valuesBelongsToParent = attr.values.filter((attrValue) => {
        if (attrValue.parent_id) {
          const parentId = attrValue.parent_id;
          return this.marketplace.category_attributes.find((item) => {
            return (
              item.hasOwnProperty("value") &&
              item.value &&
              item.value.attr_id == parentId
            );
          });
        }
      });

      if (valuesBelongsToParent && valuesBelongsToParent.length) {
        return valuesBelongsToParent;
      }

      return valuesWithOutParent.length ? valuesWithOutParent : attr.values;
    },
    getSearchForm(attr) {
      const data = {};
      data.marketplace_id = this.marketplace.market.id;
      data.code = attr.code;

      if (attr.name == "Model") {
        const parentData = this.marketplace.category_attributes.find((item) => {
          return (
            item.name == "Marka" && item.hasOwnProperty("value") && item.value
          );
        });

        if (parentData) {
          data.parent = parentData.value.attr_id;
        }
      }

      return data;
    },
    onRemoveSelected() {
      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: "Ürünün pazaryeri kategorisi kaldırılacak. Kategori özellikleri silinecek.",
        icon: "warning",
        showCancelButton: "İptal",
        confirmButtonText: "Kaldır",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.marketplace.selectedCategory = null;
          this.showNewMatch = false;
          this.clearCategoryAttributes();
        }
      });
    },
    clearCategoryAttributes() {
      this.marketplace.category_attributes = [];
    },
    changeMarketplaceActive(checked) {
      if (!checked) {
        this.$swal({
          title:
            "Uyarı: Paket bağlı olduğu tüm pazaryerlerinde satışa kapatılacaktır!",
          icon: "warning",
          showCancelButton: false,
        })
      }
    },
  },
  computed: {
    ...mapState({
      packageInfo: (state) => state.packages.info,
    }),
    marketplace: {
      get() {
        return this.marketplaces[this.index];
      },
      set(value) {
        this.marketplaces[this.index] = value;
      },
    },
    isMarketActive() {
      return this.marketplace.market.is_active;
    },
    editRoute() {
      return this.marketplace
        ? `/marketplaces/detail/${this.marketplace.market.id}`
        : "/";
    },
    getBorderClass() {
      const itemData = this.getConfig();
      if (this.isMarketActive) {
        return itemData ? itemData.borderClass : "border-dark";
      }
      return "border-secondary";
    },
    getTextClass() {
      const itemData = this.getConfig();
      if (this.isMarketActive) {
        return itemData ? itemData.textClass : "text-dark";
      }
      return "text-secondary";
    },
    checkCategoryAttributes() {
      return (
        this.marketplace.category_attributes &&
        this.marketplace.category_attributes.length
      );
    },
    submitCategoryText() {
      return this.checkCategoryAttributes
        ? "Özellikleri Tekrar Getir"
        : "Kategori Özelliklerini Getir";
    },
    matchedCategory() {
      const data = this.categories.find((item) => {
        return item.marketplaces.filter((m) => {
          return m.marketplace_id == this.marketplace.market.id;
        }).length;
      });
      let result = null;

      if (data) {
        const categoryFound = data.marketplaces.find(
          (item) => item.marketplace_id == this.marketplace.market.id
        );

        result = {};
        result.category_name = data.name;
        result.marketplace_category = categoryFound
          ? categoryFound.category_name
          : null;
      }
      return result;
    },
    priceDisabled() {
      return (
        !this.isMarketActive ||
        this.marketplace.percent_type !== "-1" ||
        this.marketplace.price_type !== "-1"
      );
    },
    matchedCategories() {
      let result = [];
      const newMatch = {
        id: null,
        category_name: "Yeni Kategori Eşleştir",
      };

      const data = this.categories.filter((item) => {
        return item.marketplaces.filter((m) => {
          return m.marketplace_id == this.marketplace.market.id;
        }).length;
      });

      let mappedData = data.map((item) => {
        const getMatchedMarketplace = item.marketplaces.find(
          (market) => market.marketplace_id == this.marketplace.market.id
        );

        const data = {};
        data.id = item.id;
        data.category_name = item.name;
        data.category_code = getMatchedMarketplace
          ? getMatchedMarketplace.category_code
          : null;
        return data;
      }, []);

      const checkNewMatch = mappedData.filter((item) => item.id === null);
      if (!checkNewMatch.length) {
        result = [...mappedData, newMatch];
      } else {
        result = mappedData;
      }

      return result;
    },
    checkMarketplaceSellPrice() {
      return this.marketplace.price_type !== "-1";
    },
    marketplaceSellPrice() {
      const percent = this.marketplace.percent || 0;
      const priceType = this.marketplace.price_type;
      const percentType = this.marketplace.percent_type;
      const price =
        priceType == "price"
          ? this.packageInfo.normalPrice.unmask
          : this.packageInfo.discountedPrice.unmask;
      let result = price;

      let multiplier = 0;
      if (percentType == "increase") multiplier = 1;
      if (percentType == "decrease") multiplier = -1;

      result = price + ((price * percent) / 100) * multiplier;

      return result;
    },
  },
  watch: {
    "marketplace.active": function (status) {
      if (status) {
        if (this.marketplace.market.name == "n11") {
          this.$emit("get-shipment-templates", this.marketplace);
        }
      }
    },
    "marketplace.category": function (newCategory) {
      if (newCategory && newCategory.id === null) {
        this.showNewMatch = true;
      } else {
        this.marketplace.selectedCategory = null;
        this.showNewMatch = false;
      }
    },
    deep: true,
  },
  mounted() {
    if (
      this.marketplace.market.name == "n11" &&
      this.marketplace.market.is_active &&
      !this.marketplace.shipment_templates.length
    ) {
      this.$emit("get-shipment-templates", this.marketplace);
    }
  },
};
</script>
